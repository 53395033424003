<template>
<!-- 国际客户详情页 -->
    <div id="app_user_detail">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                
            </div>
            <div class="head_top_title">国际客户详情</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 0px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">

                <el-row class="dialog_top cursor" @click.native="show_base = !show_base" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <i class="dialog_top_close_button" :class="show_base ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-show="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="110"  align="center"> </el-table-column>
                    <!-- <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column> -->
                    <el-table-column  min-width="200" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'states'">
                                <span>{{scope.row.show_value}}</span>
                                <el-button type="text" size="mini" @click="state_upd()"  style="margin-left:10px;">修改</el-button>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 基本属性修改按钮 -->
                <el-row v-show="show_base" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 50px;" @click="base_upd()">修改</el-button>
                </el-row>

            </el-row>
        </el-row>
        
        

        <!-- 客户状态修改组件 -->
        <InterUserUpdState :prop_change_i="prop_user_upd_state_i"
            :prop_item="detail_obj"
            @result="userUpdResult"
        ></InterUserUpdState>

        <!-- 国际客户增加组件 -->
        <InterUserAddUpd :prop_change_i="prop_user_add_i"
            :prop_type="prop_user_type"
            :prop_item="detail_obj"
            @result="userAddResult"
        ></InterUserAddUpd>
        

    </div>
</template>
<script>
import InterUserUpdState from './inter_user_upd_state.vue';//组件 客户状态修改
import InterUserAddUpd from './inter_user_add_upd.vue';//组件 客户增加
import API from '../../../api/api';

export default {
    components: {
       InterUserAddUpd,
       InterUserUpdState,
    },

    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            detail_obj : {},//客户详情的对象  通过请求接口获取

            show_base:true,//客户基本信息 是否展示  true:展示
            tableData_base:[],//客户基本信息数据
            
            //客户修改
            prop_user_add_i:0,//弹出框是否可见
            prop_user_type:'upd',//客户控价信息增加或修改  组件 类型  add：增加  upd:修改
            prop_user_item:{},//客户控价增加或修改  组件 参数对象

            //客户状态修改
            prop_user_upd_state_i:0,//弹出框是否可见

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        console.log('实例被激活时使用，用于重复激活一个实例的时候')
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));

        //初始化设置
        this.initSetUp();

        //获取数据
        this.getData();
    },
    // deactivated () {
    //     console.log('实例没有被激活时')
    // },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },

        //客户基本信息修改
        base_upd(){
            console.log("基本信息修改");
            if(this.detail_obj.cpid){
                this.prop_user_add_i ++;//弹出框是否可见
            }
        },
        userAddResult(){
            //获取数据
            this.getData();
        },
      
        //客户状态修改
        state_upd(){
            console.log("状态修改");
            if(this.detail_obj.cpid){
                this.prop_user_upd_state_i ++;//弹出框是否可见
            }

        },
        //修改结果
        userUpdResult(){
            this.getData();
        },

        //获取数据
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化

            var cpid = this.$route.params.id;
            //请求接口
            API.Int_UserServlet({
                param: "detail",
                cpid:cpid,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    var show_value = "";

                    var table_obj = {label:"客户账号",show_value:res.detail.cpid,value:res.detail.cpid,name:"cpid"};
                    this.tableData_base.push(table_obj);

                    if( 1 == 1){
                        table_obj = {label:"名称",show_value:res.detail.cpname,value:res.detail.cpname,name:"cpname"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"接口密码",show_value:res.detail.cppwd,value:res.detail.cppwd,name:"cppwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户鉴权IP",show_value:res.detail.ip,value:res.detail.ip,name:"ip"};
                        this.tableData_base.push(table_obj);


                        if(res.detail.states == 0){
                            show_value = "可用";
                        }else if(res.detail.states == 1){
                            show_value = "注销";
                        }else if(res.detail.states == 2){
                            show_value = "已删除";
                        }else if(res.detail.states == 3){
                            show_value = "暂停";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户状态",show_value:show_value,value:res.detail.states,name:"states"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户接入方式",show_value:res.detail.interface_type == 0 ? 'CMPP' : 'HTTP',value:res.detail.interface_type,name:"interface_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"回调地址",show_value:res.detail.url,value:res.detail.url,name:"url"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"备注",show_value:res.detail.remark,value:res.detail.remark,name:"remark"};
                        this.tableData_base.push(table_obj);
                    }
                }
            });
        },
        //返回
        goBack() {
            API.router_to("/inter_user_list");
        }
    }
};

</script>

<style scoped>

#app_user_detail >>> .el-badge__content.is-fixed {
  position: inherit;
}
</style>