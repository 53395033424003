<template>
<div>
    <!-- 客户管理》客户详情》客户修改-状态 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>国际客户状态修改</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <!-- 客户cpid+名称 -->
            <el-row type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                <el-table :data="tableData_titil" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="110"  align="center"> </el-table-column>
                    <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column>
                </el-table>
            </el-row>

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                    

                    <el-form-item label="客户状态">
                        <el-radio-group v-model="form_data.states" size="mini" >
                            <el-radio :label="0">可用</el-radio>
                            <el-radio :label="1">注销</el-radio>
                            <el-radio :label="3">暂停</el-radio>
                        </el-radio-group>
                    </el-form-item>
                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
                                    
            </el-row>
            

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_item:{},//客户对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            tableData_titil:[],//客户cpid+公司名

            form_data:{
                states:0,//客户状态  0:可用；1：注销；2:已删除；3:暂停
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 180);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.tableData_titil = this.$options.data().tableData_titil;//单个恢复初始化
        },
        //查询
        getData(){
            this.form_data.states = this.prop_item.states;//用户接口密码

            var table_obj = {label:"客户账号",show_value:this.prop_item.cpid,value:this.prop_item.cpid,name:"cpid"};
            this.tableData_titil.push(table_obj);

            table_obj = {label:"名称",show_value:this.prop_item.cpname,value:this.prop_item.cpname,name:"cpname"}
            this.tableData_titil.push(table_obj);
            
        },
        


        //提交
        onSubmit(){
            console.log("提交");
            var submit_obj = {};
            submit_obj.cpid = this.prop_item.cpid;
            submit_obj.param="stateUpd";
            submit_obj.states = this.form_data.states;//客户状态  0:可用；1：注销；2:已删除；3:暂停

            if(API.isEmtry(submit_obj.states)){
                this.$message.error("请选择状态");
                return;
            }
            if(submit_obj.states == this.prop_item.states){
                this.$message.error("状态未发生改变");
                return;
            }

            API.Int_UserServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，状态改变成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
/* .el-form-item {
  margin-bottom: 10px;
} */
</style>